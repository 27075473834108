<template>
    <div class="container" id="SeasonRecheckDetail">
        <el-page-header @back="$router.back()" :content="['','编辑复审资料','查看复审资料'][type]"></el-page-header>
        <div class="SeasonRecheckDetail_content">
            <el-alert
                title="审核不通过原因："
                :description="(AuditStatus.RejectReason || '')"
                type="warning"
                effect="dark"
                show-icon
                :closable="false"
                style="margin-bottom:30px"
                 v-if="AuditStatus.Status == 3">
            </el-alert>
            <el-descriptions title="复审信息" :column="1" :labelStyle="{'width':'100px','justify-content': 'end','padding-right':'12px','margin-right':'0','box-sizing':'border-box'}">
                <el-descriptions-item label="申请机构">{{CompanyName}}</el-descriptions-item>
                <el-descriptions-item label="赛季名称">{{Season.SeasonName}}</el-descriptions-item>
                <el-descriptions-item label="赛季赛事名称">{{Season.LeagueName}}</el-descriptions-item>
                <el-descriptions-item label="赛事名称">{{League.Name}}</el-descriptions-item>
                <el-descriptions-item label="比赛项目">{{League.EventsName}}</el-descriptions-item>
                <el-descriptions-item label="比赛形式">{{League.IsOnline ? '线上赛' : '线下赛'}}</el-descriptions-item>
                <el-descriptions-item label="赛事logo">
                    <img :src="League.LogoUrl" style="height:100px;" v-if="League.LogoUrl"/>
                    <span v-else>无</span>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="指标资料认证等级" :column="1" :labelStyle="{'width':'100px','justify-content': 'end','padding-right':'12px','margin-right':'0','box-sizing':'border-box'}">
                <el-descriptions-item label="最终定级" v-if="finallyLevel">
                    <el-tag
                        v-for="item in grade"
                        :key="item"
                        type="success"
                        :effect="item == finallyLevel?'dark':'plain'"
                        style="margin-right:10px"
                        size="small">
                        {{item}}
                    </el-tag>
                </el-descriptions-item>
                <el-descriptions-item label="初审等级">
                    <el-tag
                        v-for="item in grade"
                        :key="item"
                        type="danger"
                        :effect="item == tentativeLevel?'dark':'plain'"
                        style="margin-right:10px"
                        size="small">
                        {{item}}
                    </el-tag>
                </el-descriptions-item>
                <template v-for="(item, index) in TentativeIndicators">
                    <el-descriptions-item :label="item.name" :key="index" v-if="!!item.level">
                        <el-tag
                            v-for="gradeItem in grade"
                            :key="gradeItem"
                            :effect="gradeItem==item.level?'dark':'plain'"
                            style="margin-right:10px"
                            size="small">
                            {{gradeItem}}
                        </el-tag>
                    </el-descriptions-item>
                </template>
            </el-descriptions>
            <el-divider content-position="left">填写复审信息：默认显示初审资料，如与初审资料相同无需修改</el-divider>
            <el-form ref="form" :model="form" :disabled="type == 2" label-width="120px" size="small">
                <div v-if="indicatorsCheck.host" style="margin-bottom:10px">主办单位</div>
                <el-card shadow="never"  v-if="indicatorsCheck.host">
                    <el-form-item label="已添加单位:" prop="host" :rules="rules.host" :inline-message="true" style="margin-bottom:0">
                        <div style="width:100%;max-width:500px">
                            <el-tag v-for="(item,index) in form.host.list" :key="index"
                                :closable="type != 2"
                                disable-transitions effect="plain" 
                                @close="removeHost(index)" 
                            >
                            <span>{{item.name}}（{{hostLimit.levels[item.level].desc}}）</span>
                            </el-tag>
                        </div>
                    </el-form-item>
                    <el-form-item style="margin-bottom:5px">
                        <template slot="label">
                            主办单位<span style="color:#409EFF;font-weight:blod">{{form.host.list.length + 1}}</span>:
                        </template>
                        <el-input v-model.trim="form.host.name" style="width:100%;max-width:400px" placeholder="输入主办单位名称"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-bottom:10px">
                        <template slot="label">
                            单位性质<span style="color:#409EFF;font-weight:blod">{{form.host.list.length + 1}}</span>:
                        </template>
                        <el-select v-model="form.host.level" placeholder="请选择单位性质" style="width:100%;max-width:400px">
                            <el-option  v-for="(item,key) in hostLimit.levels" :key="key"
                                :label="item.desc"
                                :value="key"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-button type="primary" size="small" style="margin-left:120px" @click="addHost" title="点击添加主办单位"><i class="el-icon-plus"></i> 添加</el-button>
                </el-card>
                <div v-if="indicatorsCheck.scope" style="margin-bottom:10px">覆盖范围</div>
                <el-card shadow="never" v-if="indicatorsCheck.scope">
                    <el-form-item label="参赛队伍范围:" prop="scope"
                        :rules="rules.scope">
                        <el-select v-model="form.scope" placeholder="请选择" style="width:100%;max-width:400px">
                            <el-option  v-for="(item,key) in scopeLimit.levels" :key="key"
                                :label="item.desc"
                                :value="key"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="已添加地点:" prop="address" :rules="rules.address" :inline-message="true" style="margin-bottom:0">
                        <div style="width:100%;max-width:500px">
                            <el-tag
                                v-for="(item,index) in form.address.list" :key="index"
                                :closable="type != 2"
                                disable-transitions effect="plain"
                                @close="removedAddress(index)" 
                            >
                                <span>{{item}}</span>
                            </el-tag>
                        </div>
                    </el-form-item>
                    <el-form-item style="margin-bottom:10px">
                        <template slot="label">
                            举办地点<span style="color:#409EFF;font-weight:blod">{{form.address.list.length + 1}}</span>:
                        </template>
                        <el-input v-model.trim="form.address.value" placeholder="输入举办地点" style="width:100%;max-width:400px"></el-input>
                    </el-form-item>
                    <el-button type="primary" size="small" style="margin-left:120px" @click="addAddress()" title="点击添加举办地点"><i class="el-icon-plus"></i> 添加</el-button>
                </el-card>
                <div v-if="indicatorsCheck.scale" style="margin-bottom:10px">参赛规模</div>
                <el-card shadow="never" v-if="indicatorsCheck.scale">
                    <el-form-item v-for="(item,index) in scaleLimit.items" :key="index"
                        :label="item.name + ':'"
                        :prop="`scale.${index}`"
                        :rules="[{required:true,message:'请输入'+item.name,trigger:'blur'},
                            {type: 'number',message:'请输入数字',trigger:'blur'},
                            {validator:(rule, value, callback)=>{
                                if(value < item.min){
                                    callback(new window.Error('输入值小于最小值'));
                                }else if(value > item.max){
                                    callback(new window.Error('输入值大于最大值'));
                                }else{
                                    callback();
                                }
                        },trigger:'blur'}]">
                        <el-input v-model.number="form.scale[index]" :placeholder="item.hint || ('输入' + item.name)" style="width:100%;max-width:400px"></el-input>
                    </el-form-item>
                </el-card>
                <div v-if="indicatorsCheck.site" style="margin-bottom:10px">场地条件</div>
                <el-card shadow="never" v-if="indicatorsCheck.site">
                    <el-form-item v-for="(item,index) in siteLimit.items" :key="index"
                        :label="item.name + ':'"
                        :prop="`site.${index}`"
                        :rules="[{required:true,message:'请输入'+item.name,trigger:'blur'},
                            {type: 'number',message:'请输入数字',trigger:'blur'},
                            {validator:(rule, value, callback)=>{
                                if(value < item.min){
                                    callback(new window.Error('输入值小于最小值'));
                                }else if(value > item.max){
                                    callback(new window.Error('输入值大于最大值'));
                                }else{
                                    callback();
                                }
                        },trigger:'blur'}]">
                        <el-input v-model.number="form.site[index]" :placeholder="item.hint || ('输入' + item.name)" style="width:100%;max-width:400px"></el-input>
                    </el-form-item>
                </el-card>
                <div v-if="indicatorsCheck.judge" style="margin-bottom:10px">裁判配置</div>
                <el-card shadow="never" v-if="indicatorsCheck.judge">
                    <template  v-for="(item,index) in judgeLimit.items">
                        <el-form-item :key="index"
                            :label="item.name + ':'"
                            :prop="`judge.${index}`"
                            :rules="[{required:true,message:'请输入'+item.name,trigger:'blur'},
                                {type: 'number',message:'请输入数字',trigger:'blur'},
                                {validator:(rule, value, callback)=>{
                                    if(value < item.min){
                                        callback(new window.Error('输入值小于最小值'));
                                    }else if(value > item.max){
                                        callback(new window.Error('输入值大于最大值'));
                                    }else{
                                        callback();
                                    }
                            },trigger:'blur'}]"
                            v-if="item.type == 'number'">
                            <el-input v-model.number="form.judge[index]" :placeholder="item.hint || ('输入' + item.name)" style="width:100%;max-width:400px"></el-input>
                        </el-form-item>
                        <el-form-item :key="index"
                            :label="item.name + ':'"
                            :prop="`judge.${index}`"
                            :rules="[{required:true,message:'请选择'+item.name,trigger:'change'}]"
                            v-if="item.type == 'enum'">
                            <el-select v-model="form.judge[index]" placeholder="请选择" style="width:100%;max-width:400px">
                                <el-option v-for="(subItem,subIndex) in item.values" :key="subIndex" :value="subItem"></el-option>
                            </el-select>
                        </el-form-item>
                    </template>
                </el-card>
                <div v-if="indicatorsCheck.rounds" style="margin-bottom:10px">比赛副数</div>
                <el-card shadow="never" v-if="indicatorsCheck.rounds">
                    <el-form-item v-for="(item,index) in roundsLimit.items" :key="index"
                        :label="item.name + ':'"
                        :prop="`rounds.${index}`"
                        :rules="[{required:true,message:'请输入'+item.name,trigger:'blur'},
                            {type: 'number',message:'请输入数字',trigger:'blur'},
                            {validator:(rule, value, callback)=>{
                                if(value < item.min){
                                    callback(new window.Error('输入值小于最小值'));
                                }else if(value > item.max){
                                    callback(new window.Error('输入值大于最大值'));
                                }else{
                                    callback();
                                }
                        },trigger:'blur'}]">
                        <el-input v-model.number="form.rounds[index]" :placeholder="item.hint || ('输入' + item.name)" style="width:100%;max-width:400px"></el-input>
                    </el-form-item>
                </el-card>
                <div style="margin-bottom:10px">证明资料附件上传</div>
                <el-card shadow="never">
                    <template v-for="(item,index) in attachmentLimit">
                        <el-form-item :key="index" 
                            v-if="item.id == 2 ? false : item.id == 3 ? HasFields : true"
                            :label="item.name + ':'"
                            :prop="`attachments.${index}.files`"
                            :rules="[{required:item.mandatory=='last' || item.mandatory=='always',validator:(rule, value, callback)=>{
                                    if(rule.required && (!value || value.length == 0)){
                                        callback(new window.Error('请上传' + item.name));
                                    }else if(value && value.length > 0){
                                        if(value.filter(n=>n.state == -1).length > 0){
                                            callback(new window.Error(item.name+'有上传失败的文件'));
                                        }else{
                                            callback();
                                        }
                                    }else{
                                        callback();
                                    }
                                }}]">
                            <div class="file">
                                <div class="button">
                                    <el-button type="primary">
                                        <span>点击上传</span>
                                    </el-button>
                                    <input type="file" v-if="type != 2" @change="fileChange($event,index)"  :title="item.desc">
                                </div>
                                <span style="color:#606266;font-size:12px;margin-left:10px">{{item.desc}}</span>
                                <ul>
                                    <li v-for="(fileItem,fileIndex) in form.attachments[index].files" :key="fileIndex">
                                        <a :href="fileItem.store_path" title="点击下载">
                                            <i class="el-icon-document"></i>
                                            <span>{{fileItem.original_name}}</span>
                                        </a>
                                        <i class="state" :class="{
                                            'el-icon-success':fileItem.state == 2 || fileItem.store_path,
                                            'el-icon-error':fileItem.state == -1,
                                            'el-icon-loading':fileItem.state == 1}"></i>
                                        <i class="el-icon-close" @click="fileDelete(index,fileIndex)" title="点击删除" v-if="type != 2"></i>
                                    </li>
                                </ul>
                            </div>
                        </el-form-item>
                    </template>
                </el-card>
                <el-form-item label="联系人:"
                    prop="salutation"
                    :rules="rules.salutation">
                    <el-input v-model.trim="form.salutation" placeholder="请输入联系人名字" style="width:100%;max-width:400px"></el-input>
                </el-form-item>
                <el-form-item label="联系电话:"
                    prop="contactWay"
                    :rules="rules.contactWay">
                    <el-input v-model.trim="form.contactWay" placeholder="请输入联系人电话" style="width:100%;max-width:400px"></el-input>
                </el-form-item>
                <el-form-item  label="赛事名称在赛事证书上的显示格式:"
                    prop="leagueNameByLines"
                    :rules="rules.leagueNameByLines">
                    <div>
                        <el-input v-model.trim="form.leagueNameByLines[0]" maxlength="20" placeholder="第一行文字，每行最多20个文字" style="width:100%;max-width:400px" ></el-input>
                    </div>
                    <div>
                        <el-input v-model.trim="form.leagueNameByLines[1]" maxlength="20" placeholder="第二行文字，每行最多20个文字" style="width:100%;max-width:400px"></el-input>
                    </div>
                    <div>
                        <el-input v-model.trim="form.leagueNameByLines[2]" maxlength="20" placeholder="第三行文字，每行最多20个文字" style="width:100%;max-width:400px"></el-input>
                    </div>
                </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <el-row style="margin:30px 0 20px 100px" v-if="type != 2">
                <el-button type="success" style="width:100px" @click="send(false)">保存</el-button>
                <el-button type="primary" style="min-width:100px" @click="send(true)">保存并提交审核</el-button>
            </el-row>
        </div>
    </div>
</template>
<script>
import {
    EditSeasonReviewApply,
    SaveSeasonReviewApply
} from '@/api'
import OSS from 'ali-oss'
import { nanoid } from "nanoid"
import {formatFileSize} from "@/assets/js"
export default {
    data(){
        var seasonId = parseInt(this.$route.params.seasonId),
            type = parseInt(this.$route.params.type);
        return {
            type: isNaN(type) ? 0 : type,//0：创建 1：修改
            seasonId: isNaN(seasonId) ? 0 : seasonId,
            form:{
                host:{
                    name:"",
                    level:"",
                    list:[]
                },
                scope:"",
                address:{
                    value:"",
                    list:[],
                },
                scale:[],
                site:[],
                judge:[],
                rounds:[],
                attachments:[],
                leagueNameByLines:['','',''],
                salutation:'',//联系人
                contactWay:''//联系电话
            },
            rules:{
                host:[
                    {required:true,validator:(rule, value, callback)=>{
                        if(!value.list || value.list.length == 0){
                            callback(new Error('请添加主办单位'));
                        }else{
                            callback();
                        }
                    }}
                ],
                scope:[
                    {required:true,message:"请选择参赛队伍范围", trigger: 'change' }
                ],
                address:[
                    {required:true,validator:(rule, value, callback)=>{
                        if(!value.list || value.list.length == 0){
                            callback(new Error('请添加举办地点'));
                        }else{
                            callback();
                        }
                    }},
                ],
                salutation:[
                    {required:true,message:"请输入联系人", trigger: 'blur' }
                ],
                contactWay:[
                    {required:true,message:"请输入联系电话", trigger: 'blur' }
                ],
                leagueNameByLines:[
                    {required:true,validator:(rule, value, callback)=>{
                        let leagueNameByLines = value.filter(n=>n).join(',');
                        if(!leagueNameByLines){
                            callback(new Error("请输入赛事名称在赛事证书上的显示格式"));
                        }else if(value.filter(n=>n.length > 20).length > 0){
                            callback(new Error("每行不能超过20个文字"));
                        }else{
                            callback();
                        }
                    }, trigger: 'blur' }
                ]
            },
            SeasonId:this.$route.params.seasonId,//赛季id
            CompanyName:"",//申请机构
            Season:{},//赛季信息
            tentativeLevel:'',//初审级别
            TentativeIndicators:[],
            finallyLevel:'',//复审级别
            grade:['A','B','C','D','E'],
            League:{},//基础信息
            AuditStatus:{},//审核状态
            HasFields:true,//是否包含场地条件
            hostLimit:null,
            scopeLimit:null,
            scaleLimit:null,
            siteLimit:null,
            judgeLimit:null,
            roundsLimit:null,
            attachmentLimit:null,
            UploaderOptions:{},//oss上传临时参数
        }
    },
    computed:{
        indicatorsCheck(){
            return {
                host: this.hostLimit && this.hostLimit.form != "none",
                scope: this.scopeLimit && this.scopeLimit.form != "none",
                scale: this.scaleLimit && this.scaleLimit.form != "none",
                site: this.siteLimit && this.siteLimit.form != "none",
                judge: this.judgeLimit && this.judgeLimit.form != "none",
                rounds: this.roundsLimit && this.roundsLimit.form != "none"
            }
        }
    },
    created(){
        EditSeasonReviewApply({
            SeasonId: this.seasonId,
        }).then(data=>{
            if(data.Code == 0){
                this.League = data.League;
                // this.Owner = data.Owner;
                this.UploaderOptions = data.UploaderOptions;
                var IndicatorsLimit = data.EventsCertificateDefine.Indicators;
                this.TentativeIndicators = data.ReviewData.TentativeIndicators;
                this.hostLimit = IndicatorsLimit[0];
                this.scopeLimit = IndicatorsLimit[1];
                this.scaleLimit = IndicatorsLimit[2];
                this.siteLimit = IndicatorsLimit[3];
                this.judgeLimit = IndicatorsLimit[4];
                this.roundsLimit = IndicatorsLimit[5];
                this.attachmentLimit = data.EventsCertificateDefine.AttachmentKinds;
                this.HasFields = data.EventsCertificateDefine.HasFields;

                let SeasonData = data.ReviewData;
                this.Season = SeasonData.Season;
                this.tentativeLevel = SeasonData.TentativeLevel;
                this.finallyLevel = SeasonData.ApplyData.FinallyLevel;
                this.AuditStatus = SeasonData.AuditStatus;
                this.form.salutation = SeasonData.ApplyData.Contacts.Salutation;
                this.form.contactWay = SeasonData.ApplyData.Contacts.ContactWay;

                let IndicatorsData = SeasonData.ApplyData.Indicators,
                    AttachmentsData = SeasonData.ApplyData.Attachments;
                let {
                        levels: hostLevels = [], 
                        values: hostValues = []
                    } = IndicatorsData[0],
                    scopeData = IndicatorsData[1],
                    scaleData = IndicatorsData[2],
                    siteData = IndicatorsData[3],
                    judgeData = IndicatorsData[4],
                    roundsData = IndicatorsData[5];
                //主办单位
                if(IndicatorsLimit[0] && IndicatorsLimit[0].form != 'none'){
                    let hostList = [];
                    for(var i=0;i<hostLevels.length;i++){
                        hostList.push({
                            level: hostLevels[i],
                            name: hostValues[i]
                        })
                    }
                    this.$set(this.form.host, 'list', hostList);
                }
                //覆盖范围
                if(IndicatorsLimit[1] && IndicatorsLimit[1].form != 'none'){
                    //参赛队伍范围
                    this.form.scope = scopeData.level;
                    //举办地点
                    this.$set(this.form.address, 'list', scopeData.value ? scopeData.value.split(',') : []);
                }
                //参赛规模
                if(IndicatorsLimit[2] && IndicatorsLimit[2].form != 'none'){
                    this.$set(this.form, 'scale', scaleData.values || []);
                }
                //场地条件
                if(IndicatorsLimit[3] && IndicatorsLimit[3].form != 'none'){
                    this.$set(this.form, 'site', siteData.values || []);
                }
                //裁判配置
                if(IndicatorsLimit[4] && IndicatorsLimit[4].form != 'none'){
                    this.$set(this.form, 'judge', judgeData.values || []);
                }
                //比赛副数
                if(IndicatorsLimit[5] && IndicatorsLimit[5].form != 'none'){
                    this.$set(this.form, 'rounds', roundsData.values || []);
                }
                //文件数据
                this.form.attachments = AttachmentsData || [];
                this.CompanyName=data.CompanyName;
                let LeagueNameByLinesData = SeasonData.ApplyData.LeagueNameByLines ? SeasonData.ApplyData.LeagueNameByLines.split(',') : null;
                if(LeagueNameByLinesData){
                    let leagueNameByLines = ['','',''];
                        leagueNameByLines.splice(0,LeagueNameByLinesData.length,...LeagueNameByLinesData);
                    this.$set(this.form,'leagueNameByLines',leagueNameByLines)
                }else{
                    this.form.leagueNameByLines = [data.League.Name,'',''];
                }
            }
        })
    },
    methods: {
        addHost(){
            var hostObj = this.form.host;
            var list = hostObj.list;
            if(hostObj.name && hostObj.level){
                list.push({
                    name: hostObj.name,
                    level: hostObj.level
                })
                this.$set(this.form,'host',{list,name:"",level:""});
                this.$refs['form'].validateField("host");
            }
        },
        removeHost(index){
            var hostObj = this.form.host;
            var list = hostObj.list;
            list.splice(index,1);
            this.$set(this.form.host,'list',list);
            this.$refs['form'].validateField("host");
        },
        addAddress(){
            var addressObj = this.form.address;
            var list = addressObj.list;
            if(addressObj.value){
                list.push(addressObj.value)
                this.$set(this.form,'address',{list,value:""});
                this.$refs['form'].validateField("address");
            }
        },
        removedAddress(index){
            var addressObj = this.form.address;
            var list = addressObj.list;
            list.splice(index,1);
            this.$set(this.form.address,'list',list);
            this.$refs['form'].validateField("address");
        },
        fileChange(event,index){
            let files = event.target.files, file;
            let obj = this.form.attachments[index];
            let list = obj.files;
            if (files && files.length > 0) {
                file = files[0];
                if(!file) return; 
                var acceptArr = [],
                    // acceptArr1 = ['image/jpeg','image/png','image/bmp'],
                    acceptArr2 = ['image/jpeg','image/png','image/bmp','pdf','word',
                        'excel','zip','application/pdf','application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        'application/vnd.ms-excel','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/x-zip-compressed'];
                acceptArr = acceptArr2;
                if(acceptArr.indexOf(file.type) == -1){
                    this.$message.error('不支持此文件类型');
                    return;
                }
                if (file.size > 100*1024*1024) {
                    this.$message.error('文件大小不能超过 100MB!');
                    return;
                }
                
                let listId = nanoid();
                list.push({
                    listId,
                    original_name: file.name,
                    size: formatFileSize(file.size),
                    state: 1//1上传中 -1上传失败 2或store_path参数不为空就是成功
                })
                obj.files = list;
                this.$set(this.form.attachments,index,obj)
                this.$refs['form'].validateField("attachments."+index+".files");
                this.ossUpload({
                    listId,
                    fileData: file,
                    valuesIndex: index
                })
            }
            event.target.value = "";
        },
        ossUpload({listId, fileData, valuesIndex}){
            const _this = this;
            const options = this.UploaderOptions;
            const client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: options.Region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: options.AccessKeyId,
                accessKeySecret: options.AccessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: options.SecurityToken,
                // 填写Bucket名称。
                bucket: options.Bucket
            });
            const headers = {
                'Cache-Control': 'public',
                'Content-Disposition': fileData.name,
            }
            let nameID = options.ObjectNamePrefix + nanoid();
            // console.log(client)
            client.put(nameID, fileData, headers).then(function (r1) {
                _this.fileUploadState({listId, valuesIndex, state: 2,url:r1.url});
                console.log('put success:');
            }).catch(function (err) {
                _this.fileUploadState({listId, valuesIndex, state: -1});
                console.log('error: '+JSON.stringify(err));
            });
        },
        fileUploadState({listId, valuesIndex, state,url}){
            var obj = this.form.attachments[valuesIndex];
            var list = obj.files;
            var fileObj = null,
                fileIndex = -1;
            for(var i=0;i<list.length;i++){
                if(list[i].listId == listId){
                    fileObj = list[i];
                    fileIndex = i;
                    break;
                }
            }
            if(fileIndex > -1){
                if(state == 2){
                    fileObj.store_path = url;
                    fileObj.state = 2;
                }else {
                    fileObj.state = -1;
                }
                list[fileIndex] = fileObj;
                obj.files = list;
                this.$set(this.form.attachments,valuesIndex,obj);
            }
        },
        fileDelete(valuesIndex,filesIndex){//删除文件
            this.$confirm('将删除您已添加的文件，请确认操作').then(() => {
                let obj = this.form.attachments[valuesIndex];
                let list = obj.files;
                list.splice(filesIndex, 1);
                obj.files = list;
                this.$set(this.form.attachments, valuesIndex, obj);
            })
        },
        send(isRecheck){
            this.$refs['form'].validate((valid)=>{
                if(valid){
                    let indicatorsCheck = this.indicatorsCheck;
                    let form = this.form,
                        hostLevels = [],
                        hostValues = [];
                    if(indicatorsCheck.host){
                        let hostList = form.host.list;
                        for(var i=0;i<hostList.length;i++){
                            hostLevels.push(hostList[i].level);
                            hostValues.push(hostList[i].name);
                        }
                    }
                    var Attachments = [];
                    //文件
                    let attachmentLimit = this.attachmentLimit;
                    let attachmentValues = form.attachments;
                    for(let i=0;i<attachmentLimit.length;i++){
                        let files = attachmentValues[i].files;
                        let list = [];
                        if(files.length > 0){
                            for(let j=0;j<files.length;j++){
                                list.push({
                                    original_name: files[j].original_name,
                                    size: files[j].size,
                                    store_path: files[j].store_path
                                })
                            }
                        }
                        Attachments[i] = {
                            id: attachmentLimit[i].id,
                            name: attachmentLimit[i].name,
                            files: list
                        }
                    }
                    let leagueNameByLines = form.leagueNameByLines.filter(n=>n).join(',');
                    var Indicators = [];
                    //主办单位
                    Indicators.push(indicatorsCheck.host ? {
                        levels: hostLevels,
                        values: hostValues
                    } : {});
                    //覆盖范围
                    Indicators.push(indicatorsCheck.scope ? {
                        level: form.scope,
                        value: form.address.list.join(','),
                    } : {});
                    //参赛规模
                    Indicators.push(indicatorsCheck.scale ? {values: form.scale} : {});
                    //场地条件
                    Indicators.push(indicatorsCheck.site ? {values: form.site} : {});
                    //裁判配置
                    Indicators.push(indicatorsCheck.judge ? {values: form.judge} : {});
                    //比赛副数
                    Indicators.push(indicatorsCheck.rounds ? {values: form.rounds} : {});
                    let Contacts = {
                        Salutation: form.salutation,
                        ContactWay: form.contactWay
                    }
                    const loading = this.$loading({
                        lock: true,
                        text: '保存中',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    SaveSeasonReviewApply({
                        "SeasonId": this.seasonId,
                        "ApplyData": {
                            "Contacts": Contacts,
                            "Indicators": Indicators,
                            "Attachments": Attachments,
                            "FinallyLevel": this.finallyLevel,
                            "LeagueNameByLines": leagueNameByLines
                        },
                        "SubmitAfterSave": isRecheck
                    }).then(data=>{
                        loading.close();
                        if(data.Code == 0){
                            this.$message.success('保存成功，2秒后返回列表');
                            setTimeout(()=>{
                                this.$router.back();
                            },1500)
                        }
                    }).catch(()=>{
                        loading.close();
                    })
                }else{
                    this.$message.error('填写信息有误，请查看！');
                }
            })
            
        },
    }
}
</script>
<style scoped>
.el-card {
    width:100%;
    max-width:600px;
    box-sizing: border-box;
    margin-bottom:20px;
}
.el-card>.el-form-item:last-of-type{
    margin-bottom: 0 !important;
}
.file > .button{
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.file > .button input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.file ul {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
}
.file ul li{
    width: 100%;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    padding: 0 3px;
    box-sizing: border-box;
    line-height: 26px;
}
.file ul li a{
    width: calc(100% - 25px);
    color: #606266;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.file ul li a i {
    margin-right: 5px;
}
.file ul li a span{
    width: calc(100% - 25px);
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}
.file ul li > i.el-icon-success{
    color: #67c23a;
}
.file ul li > i.el-icon-error{
    color: #f56c6c;
}
.file ul li > i.el-icon-loading{
    color: #409eff;
}
.file ul li > i.el-icon-close{
    cursor: pointer;
    display: none;
}
.file ul li:hover{
    background: #ecf5ff;
}
.file ul li:hover a span{
    color: #79bbff;
}
.file ul li:hover > i.el-icon-close{
    display: block;
    color: #606266;
}
.file ul li:hover>.state{
    display: none;
}
</style>
<style>
    
#SeasonRecheckDetail .el-card .el-card__body{
    padding: 20px 10px !important;
}
#SeasonRecheckDetail .el-tag{
    margin-right:10px;
    margin-bottom:5px;
    max-width: 100%;
}
#SeasonRecheckDetail .el-tag>span{
    width:calc(100% - 10px);
    display: inline-block;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    vertical-align: top;
}
#SeasonRecheckDetail .el-tag>i{
    margin-left: -5px;
}
</style>